<template>
  <div :class="$style.page">
    <div :class="$style.wrapper">
      <el-button size="small" type="primary" @click="submitForm('form')">
        Создать
      </el-button>
    </div>
    <el-form
      :class="$style.form"
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="200px"
    >
      <el-form-item label="Имя пользователя" prop="firstName">
        <el-input v-model="form.firstName"></el-input>
      </el-form-item>

      <el-form-item label="Фамилия" prop="lastName">
        <el-input v-model="form.lastName"></el-input>
      </el-form-item>

      <el-form-item label="Почта" prop="email">
        <el-input v-model="form.email"></el-input>
      </el-form-item>

      <el-form-item label="Телефон" prop="phone">
        <el-input v-model="form.phone"></el-input>
      </el-form-item>

      <el-form-item label="Дата рождения">
        <el-date-picker
          v-model="form.birthday"
          type="date"
          :class="$style.date"
          placeholder="Выберите дату"
          :picker-options="datePickerOptions"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import regexp from '@/helpers/regexp.js'
import notifications from '@/mixins/notifications.js'

import { ADDWINE_ADMIN_ROUTES } from '@/constants/routing'

export default {
  ADDWINE_ADMIN_ROUTES,
  mixins: [notifications],
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        birthday: null
      },
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date()
        }
      },
      rules: {
        firstName: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ],
        email: [
          {
            pattern: regexp.email,
            message: 'Введен недопустимый символ',
            trigger: 'change'
          }
        ],
        phone: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          },
          {
            pattern: regexp.phone,
            message: 'Введен недопустимый символ',
            trigger: 'change'
          }
        ]
      }
    }
  },
  methods: {
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true
          })

          const data = {
            ...this.form,
            phone: this.form.phone.replace(/[^+0-9]/g, '')
          }

          const { error } = await delivery.AccountsCore.UsersActions.create(
            data
          )

          loading.close()

          if (error === 'Ошибка создания пользователя') {
            this.showNotification(
              'Пользователем с таким именем или телефоном уже существует',
              'error'
            )
            return
          }

          if (error) {
            this.showNotification('Ошибка создания пользователя', 'error')
            return
          }

          this.$router.push(ADDWINE_ADMIN_ROUTES.USERS.LIST)
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.page {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
  }
  .form {
    & > div > label {
      text-align: left;
    }

    .date {
      width: 100%;
    }
  }
}
</style>
